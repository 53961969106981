module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToTheme":"src/theme.js","stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WithOlesia","short_name":"WithOlesia","start_url":"/index.html","background_color":"#FFFFFF","theme_color":"#008080","display":"minimal-ui","icon":"src/images/MaterialIcon@0.5x.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"16c4473ab434757e499a501a8159c926"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1195631567481292"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
